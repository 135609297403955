/**
 * Convention de nommage :
 * Les icônes finissent par "Icon"
 *
 * Les composants ont le même nom que leurs fichiers
 * Les pages listes finissent par "List"
 * Les pages détails finissent par "Details"
 *
 * Les routes finissent par "Route" quand children est à null
 * ou undefined, "Routes" quand children est un tableau
 *
 * Quand cela est possible, définir la route d'une liste au pluriel,
 * et les details au singulier
 */

import React from "react";
import Async from "../layouts/components/Async";

// #region Import des icônes
// import { Dashboard as DashboardIcon } from "@material-ui/icons";
import { ChatBubble as ChatBubbleIcon, Description as DescriptionIcon } from "@material-ui/icons";
import { User as UserIcon, Users as UsersIcon } from "react-feather";
// #endregion Icônes

// #region Imports et définitions des routes du projet
// #region Composants de bases
// -- Imports --
const SignIn = Async(() => import("../pages/auth/SignIn"));
const ResetPassword = Async(() => import("../pages/auth/ResetPassword"));
const Page404 = Async(() => import("../pages/auth/Page404"));
const Page500 = Async(() => import("../pages/auth/Page500"));
const PageNetworkError = Async(() => import("../pages/auth/PageNetworkError"));
// const Dashboard = Async(() => import("../pages/pages/Dashboard"));
// -- Définitions --
const authRoutes = {
	id: "auth",
	path: "/auth",
	icon: <UsersIcon />,
	children: [
		{
			path: "/auth/login",
			name: "connexion",
			component: SignIn,
		},
		{
			path: "/auth/reset-password",
			name: "resetPassword",
			component: ResetPassword,
		},
		{
			path: "/404",
			name: "pageNonTrouvée",
			component: Page404,
		},
		{
			path: "/500",
			name: "erreurInterne",
			component: Page500,
		},
		{
			path: "/networkerror",
			name: "erreurRéseau",
			component: PageNetworkError,
		},
	],
};
// const dashboardRoute = {
// 	id: "dashboard",
// 	path: "/",
// 	icon: <DashboardIcon />,
// 	component: Dashboard,
// 	containsHome: true,
// };
// #endregion Composants de bases

// #region Profil
// -- Imports --
const Profile = Async(() => import("../pages/pages/Profile"));
// -- Définitions --
const profileRoutes = {
	id: "profile",
	path: "/profil",
	icon: <UserIcon />,
	component: Profile,
};
// #endregion Profil

// #region Cotisations
// -- Imports --
const SalesInvoicesList = Async(() => import("../pages/pages/SalesInvoiceList"));
const SalesInvoiceDetails = Async(() => import("../pages/pages/SalesInvoiceDetails"));
// -- Définitions --
const salesInvoiceListRoute = {
	id: "salesInvoices",
	path: "/",
	icon: <DescriptionIcon />,
	component: SalesInvoicesList,
};
const salesInvoiceDetailsRoute = {
	id: "salesInvoiceDetails",
	path: "/sales-invoice/:No",
	component: SalesInvoiceDetails,
};
// #endregion Cotisations

// #region Chat
// -- Imports --
const Chat = Async(() => import("../pages/pages/Chat"));
// -- Définitions --
const chatRoute = {
	id: "chat",
	path: "/chat",
	icon: <ChatBubbleIcon />,
	component: Chat,
};
// #endregion Chat
// #endregion Imports et définitions des routes du projet

// #region Export des routes
// Routes de bases nécessaires au projet
export const auth = [authRoutes];

// Toutes les autres pages utilisées au cours du projet
// L'ordre n'as pas d'importance ici, mais il est toujours bon d'être organisé
export const dashboard = [
	// dashboardRoute,
	salesInvoiceListRoute,
	salesInvoiceDetailsRoute,
	profileRoutes,
	chatRoute,
];

// Définition du menu (sidebar)
// L'ordre ici défini la structure du menu, c'est donc très important !
export default [
	// dashboardRoute, // TDB
	salesInvoiceListRoute,
	chatRoute,
];
// #endregion Export des routes
